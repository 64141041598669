import fp from '@fingerprintjs/fingerprintjs';

const FP_KEY = 'ct_fp';

const getFPFromStorage = () => localStorage.getItem(FP_KEY);
const setFPToStorage = (fp: string) => localStorage.setItem(FP_KEY, fp);

const get = async () => {
  const fpFromStorage = getFPFromStorage();
  if (fpFromStorage) return fpFromStorage;

  const load = await fp.load({ monitoring: false });
  const fingerprint = await load.get();
  const fpString = fingerprint.visitorId;

  setFPToStorage(fpString);

  return fpString;
};

const fingerprint = { get };

export default fingerprint;
