const POLICY_URL = 'https://trogiup.chotot.com/nguoi-ban/hoat-dong';
const PRIVACY_URL = 'https://trogiup.chotot.com/nguoi-ban/chinh-sach-bao-mat-cua-cho-tot';
const SUPPORT_URL = 'https://trogiup.chotot.com/lien-he';

const CHOTOT_LOGO = 'https://static.chotot.com/storage/marketplace/logo.png';
const PTY_LOGO = 'https://static.chotot.com/storage/marketplace/pty-orange-logo.png';
const JOB_LOGO = 'https://static.chotot.com/storage/marketplace/job-green-logo.png';
const VEH_LOGO = 'https://static.chotot.com/storage/marketplace/veh-red-logo.png';
const LOGIN_BACKGROUND_IMAGE =
  'https://static.chotot.com/storage/marketplace/login-background.webp';

const FINGERPRINT_HEADER = 'ct-fingerprint';
const FINGERPRINT_HEADER_V2 = 'ct-idfp';

export {
  POLICY_URL,
  PRIVACY_URL,
  SUPPORT_URL,
  CHOTOT_LOGO,
  PTY_LOGO,
  JOB_LOGO,
  VEH_LOGO,
  LOGIN_BACKGROUND_IMAGE,
  FINGERPRINT_HEADER,
  FINGERPRINT_HEADER_V2,
};
