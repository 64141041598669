import { useState } from 'react';
import Cookies from 'js-cookie';
import config from '~/config';

type Error = {
  message: string;
} & any;

const useRequest = <T>(isPrivate: boolean = false) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const originFetch = async (
    url: string,
    options: RequestInit & { baseUrl?: string } = {}
  ): Promise<{ data: T | null; error: Error | null }> => {
    const { baseUrl = config.gatewayUrl, ...fetchOptions } = options;
    const privateToken = Cookies.get('privateToken');
    const headers = {
      'Content-Type': 'application/json',
      ...(fetchOptions?.headers || {}),
      ...(isPrivate ? { Authorization: privateToken ? `Bearer ${privateToken}` : '' } : undefined),
    };

    setLoading(true);
    try {
      const res = await fetch(baseUrl + url, { ...fetchOptions, headers });
      const json = await res.json();

      if (res.status !== 200) {
        setError(json);
        setLoading(false);

        return { data: null, error: json };
      }

      setData(json);
      setLoading(false);

      return { data: json, error: null };
    } catch (err) {
      const error = { message: 'Đã có lỗi xảy ra, vui lòng thử lại sau.' };
      setError(error);
      setLoading(false);

      return { data: null, error };
    }
  };

  return { loading, data, error, originFetch };
};

export default useRequest;
