import useRequest from './useRequest';
import { FINGERPRINT_HEADER, FINGERPRINT_HEADER_V2 } from '~/constants';

const useRegister = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = ({ phone, password, fullName = '', token }) => {
    const body = {
      phone,
      password,
      full_name: fullName || undefined,
      token,
      platform: 'w',
    };

    return originFetch('/v3/public/auth/register', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  };

  return { ...rest, fetch };
};

const useSendOTP = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = (phone, type = '') => {
    const body = {
      phone,
      type,
    };

    return originFetch('/v2/public/auth/send_otp_verify', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  };

  return { ...rest, fetch };
};

const useVerifyOTP = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = ({ otp, phone, fingerprint = '', fingerprintV2 = '' }) => {
    const body = {
      phone,
      otp,
    };

    return originFetch('/v2/public/auth/verify', {
      method: 'POST',
      headers: {
        [FINGERPRINT_HEADER]: fingerprint,
        [FINGERPRINT_HEADER_V2]: fingerprintV2,
      },
      body: JSON.stringify(body),
    });
  };

  return { ...rest, fetch };
};

const useForgetPassword = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = ({
    phone = '',
    token = '',
    otp = '',
    password = '',
    fingerprint = '',
    fingerprintV2 = '',
    otp_key = '',
  }) => {
    const body = {
      phone,
      token,
      otp,
      password,
      platform: 'w',
      otp_key,
    };

    return originFetch('/v3/public/auth/forget_password', {
      method: 'POST',
      headers: {
        [FINGERPRINT_HEADER]: fingerprint,
        [FINGERPRINT_HEADER_V2]: fingerprintV2,
      },
      body: JSON.stringify(body),
    });
  };

  return { ...rest, fetch };
};

const useVerifySNS = () => {
  const { originFetch, ...rest } = useRequest(true);

  const fetch = ({ otp, phone, fingerprint = '', fingerprintV2 = '' }) => {
    const body = {
      phone,
      otp,
    };

    return originFetch('/v2/private/auth/verify_sns', {
      method: 'POST',
      headers: {
        [FINGERPRINT_HEADER]: fingerprint,
        [FINGERPRINT_HEADER_V2]: fingerprintV2,
      },
      body: JSON.stringify(body),
    });
  };

  return { ...rest, fetch };
};

export { useRegister, useSendOTP, useVerifyOTP, useForgetPassword, useVerifySNS };
